import * as React from 'react';

import { createLogger } from '@/shared/lib/logging/logger';
import { MultiversalAppBootstrapPageProps } from '@/shared/types/multiversal-app-bootstrap-page-props';
import { MultiversalAppBootstrapProps } from '@/shared/types/multiversal-app-bootstrap-props';
import { MultiversalPageProps } from '@/shared/types/multiversal-page-props';
import { OnlyBrowserPageProps } from '@/shared/types/only-browser-page-props';

const logger = createLogger('BrowserPageBootstrap');

export type BrowserPageBootstrapProps = MultiversalAppBootstrapProps<
MultiversalPageProps & MultiversalAppBootstrapPageProps
>;

/**
 * Bootstraps the page, only when rendered on the browser
 *
 * @param props
 */
const BrowserPageBootstrap = (props: BrowserPageBootstrapProps): JSX.Element => {
  const { Component, err, router } = props;

  const LayoutComponent = Component.Layout ?? React.Fragment;

  // When the page is served by the browser, some browser-only properties are available
  // eslint-disable-next-line react/destructuring-assignment
  const pageProps = props.pageProps as unknown as MultiversalPageProps<OnlyBrowserPageProps>;

  const injectedPageProps: MultiversalPageProps<OnlyBrowserPageProps> = {
    ...pageProps,
  };

  return (
    <LayoutComponent>
      <Component
        {...injectedPageProps}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore∫
        error={err}
      />
    </LayoutComponent>
  );
};

export default BrowserPageBootstrap;
