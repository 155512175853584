export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash }),
  },
  faq: {
    $url: (url?: { hash?: string }) => ({ pathname: '/faq' as const, hash: url?.hash }),
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash }),
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  android_chrome_192x192_png: '/android-chrome-192x192.png',
  android_chrome_512x512_png: '/android-chrome-512x512.png',
  apple_touch_icon_png: '/apple-touch-icon.png',
  favicon_16x16_png: '/favicon-16x16.png',
  favicon_32x32_png: '/favicon-32x32.png',
  favicon_ico: '/favicon.ico',
  maskable_icon_png: '/maskable_icon.png',
  site_webmanifest: '/site.webmanifest',
  static: {
    images: {
      $404_png: '/static/images/404.png',
      circle_scatter_svg: '/static/images/circle-scatter.svg',
      logo_og_png: '/static/images/logo-og.png',
      stars_svg: '/static/images/stars.svg',
      vercel_svg: '/static/images/vercel.svg',
    },
  },
} as const;

export type StaticPath = typeof staticPath;
